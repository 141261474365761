.rainbow-vertical-section-overflow_action-text, .rainbow-vertical-section-overflow_action-label, .rainbow-vertical-section-overflow_action-description {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.rainbow-vertical-section-overflow_container {
  transition: all 0.45s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  -webkit-transition: all 0.45s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  -moz-transition: all 0.45s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  -ms-transition: all 0.45s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  -o-transition: all 0.45s cubic-bezier(0.23, 1, 0.32, 1) 0ms; }
  .rainbow-vertical-section-overflow_container .rainbow-vertical-item_action {
    padding: 0.5rem 1rem 0.5rem 3rem; }

.rainbow-vertical-section-overflow_container--expanded {
  background-color: rgba(244, 246, 249, 0.4);
  box-shadow: inset 0 0 2px 0 #e3e5ed;
  padding-bottom: 16px; }

.rainbow-vertical-section-overflow_button {
  border: 0;
  display: -ms-flexbox;
  display: flex;
  padding: 1rem 1rem 1rem 1.25rem;
  border-radius: 0;
  color: #576574;
  font-size: 0.875rem;
  background-clip: border-box;
  background-color: transparent;
  text-decoration: none;
  width: 100%;
  cursor: pointer;
  -webkit-appearance: none;
  white-space: normal;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: all 0.15s cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transition: all 0.15s cubic-bezier(0.4, 0, 0.2, 1);
  -moz-transition: all 0.15s cubic-bezier(0.4, 0, 0.2, 1);
  -ms-transition: all 0.15s cubic-bezier(0.4, 0, 0.2, 1);
  -o-transition: all 0.15s cubic-bezier(0.4, 0, 0.2, 1); }
  .rainbow-vertical-section-overflow_button:focus {
    outline: 0;
    background-color: #f4f6f9; }

.rainbow-vertical-section-overflow_button--expanded {
  padding-bottom: 0; }

.rainbow-vertical-section-overflow--show {
  opacity: 1;
  transition: opacity 0.15s cubic-bezier(0.4, 0, 0.2, 1), max-height 0.15s cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transition: opacity 0.15s cubic-bezier(0.4, 0, 0.2, 1), max-height 0.15s cubic-bezier(0.4, 0, 0.2, 1);
  -moz-transition: opacity 0.15s cubic-bezier(0.4, 0, 0.2, 1), max-height 0.15s cubic-bezier(0.4, 0, 0.2, 1);
  -ms-transition: opacity 0.15s cubic-bezier(0.4, 0, 0.2, 1), max-height 0.15s cubic-bezier(0.4, 0, 0.2, 1);
  -o-transition: opacity 0.15s cubic-bezier(0.4, 0, 0.2, 1), max-height 0.15s cubic-bezier(0.4, 0, 0.2, 1); }

.rainbow-vertical-section-overflow--hide {
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  transition: opacity 0.15s cubic-bezier(0.4, 0, 0.2, 1), max-height 0.15s cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transition: opacity 0.15s cubic-bezier(0.4, 0, 0.2, 1), max-height 0.15s cubic-bezier(0.4, 0, 0.2, 1);
  -moz-transition: opacity 0.15s cubic-bezier(0.4, 0, 0.2, 1), max-height 0.15s cubic-bezier(0.4, 0, 0.2, 1);
  -ms-transition: opacity 0.15s cubic-bezier(0.4, 0, 0.2, 1), max-height 0.15s cubic-bezier(0.4, 0, 0.2, 1);
  -o-transition: opacity 0.15s cubic-bezier(0.4, 0, 0.2, 1), max-height 0.15s cubic-bezier(0.4, 0, 0.2, 1); }

.vertical-overflow-container > .rainbow-vertical-item_icon {
  top: 0.75rem;
  left: 2rem; }

.rainbow-vertical-section-overflow_action-text {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex: 1; }

.rainbow-vertical-section-overflow_action-label {
  font-size: 0.9375rem;
  color: #01b6f5;
  max-width: 100%; }

.rainbow-vertical-section-overflow_action-description {
  font-size: 0.625rem;
  line-height: 1rem;
  color: #a4a7b5;
  text-align: left;
  max-width: 100%;
  transition: all 0.15s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  -webkit-transition: all 0.15s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  -moz-transition: all 0.15s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  -ms-transition: all 0.15s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  -o-transition: all 0.15s cubic-bezier(0.23, 1, 0.32, 1) 0ms; }

.rainbow-vertical-section-overflow_action-description--expanded {
  opacity: 0; }

.rainbow-vertical-section-overflow_icon {
  margin-left: 0.5rem;
  margin-top: 0.625rem;
  transform: rotate(0deg);
  transition: transform 0.15s linear;
  -webkit-transition: transform 0.15s linear;
  -moz-transition: transform 0.15s linear;
  -ms-transition: transform 0.15s linear;
  -o-transition: transform 0.15s linear; }

.rainbow-vertical-section-overflow-icon--expanded {
  transform: rotate(-180deg);
  transition: transform 0.15s linear;
  -webkit-transition: transform 0.15s linear;
  -moz-transition: transform 0.15s linear;
  -ms-transition: transform 0.15s linear;
  -o-transition: transform 0.15s linear; }
