@media (max-width: 600px) {
  .rainbow-time-picker_modal {
    border-radius: 0 0 0.875rem 0.875rem;
    align-self: flex-start;
    width: 100vw; }
  .rainbow-time-picker_modal > .rainbow-modal_content {
    max-height: none;
    padding: 16px 8px; }
  .rainbow-time-picker_time-select-content {
    width: 100%;
    margin: 56px 0 40px 0; }
  .rainbow-time-picker_time-select-value {
    width: 24%;
    font-size: 34px;
    font-weight: 100; }
    .rainbow-time-picker_time-select-value::placeholder {
      font-size: 34px;
      font-weight: 100; }
  .rainbow-time-picker_select-ampm {
    width: 24%;
    font-size: 34px; }
  .rainbow-time-picker_dots {
    margin: 0; }
  .rainbow-time-picker_icon-button-container {
    margin-left: 0; } }
