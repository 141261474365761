.rainbow-slider_container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center; }

.rainbow-slider {
  display: -ms-flexbox;
  display: flex;
  position: relative;
  width: 100%;
  padding: 0.75rem 0; }

.rainbow-slider_range {
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 100%;
  margin: 0.5rem 0;
  background: transparent;
  border-radius: 0.4rem; }
  .rainbow-slider_range::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    background: #01b6f5;
    border: 0;
    box-shadow: rgba(0, 0, 0, 0.16) 0 2px 3px;
    cursor: pointer;
    transition: all 0.3s ease 0s;
    margin-top: calc(((1rem / 2) - (4px / 2)) * -1); }
  .rainbow-slider_range::-webkit-slider-runnable-track {
    width: 100%;
    height: 4px;
    cursor: pointer;
    background: #e3e5ed;
    border-radius: 0.4rem; }
  .rainbow-slider_range::-moz-range-thumb {
    -moz-appearance: none;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    background: #01b6f5;
    border: 0;
    box-shadow: rgba(0, 0, 0, 0.16) 0 2px 3px;
    cursor: pointer;
    transition: background 0.15s ease-in-out; }
  .rainbow-slider_range::-moz-range-track {
    width: 100%;
    height: 4px;
    cursor: pointer;
    background: #e3e5ed;
    border-radius: 0.4rem; }
  .rainbow-slider_range::-ms-track {
    width: 100%;
    height: 4px;
    cursor: pointer;
    border-radius: 0.4rem;
    background: transparent;
    border-color: transparent;
    color: transparent; }
  .rainbow-slider_range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    border-radius: 12rem;
    background: #01b6f5;
    border: 0;
    box-shadow: rgba(0, 0, 0, 0.16) 0 2px 3px;
    cursor: pointer;
    transition: background 0.15s ease-in-out; }
  .rainbow-slider_range:focus {
    outline: 0; }
  .rainbow-slider_range::-webkit-slider-thumb:hover {
    background-color: #009acf; }
  .rainbow-slider_range::-moz-range-thumb:hover {
    background-color: #009acf; }
  .rainbow-slider_range::-ms-thumb:hover {
    background-color: #009acf; }
  .rainbow-slider_range:focus::-webkit-slider-thumb {
    background-color: #01b6f5;
    box-shadow: 0 0 2px #01b6f5; }
  .rainbow-slider_range:active::-webkit-slider-thumb {
    background-color: #01b6f5;
    transition: all 0.3s ease 0s;
    transform: scale3d(1.5, 1.5, 1); }
  .rainbow-slider_range:focus::-moz-range-thumb {
    background-color: #01b6f5;
    box-shadow: 0 0 2px #01b6f5; }
  .rainbow-slider_range:active::-moz-range-thumb {
    background-color: #01b6f5; }
  .rainbow-slider_range[disabled]::-webkit-slider-thumb {
    background-color: #e3e5ed;
    cursor: default; }
  .rainbow-slider_range[disabled]::-webkit-slider-runnable-track {
    background-color: #e3e5ed;
    cursor: default; }
  .rainbow-slider_range[disabled]::-moz-range-thumb {
    background-color: #e3e5ed;
    cursor: default; }
  .rainbow-slider_range[disabled]::-moz-range-track {
    background-color: #e3e5ed; }
  .rainbow-slider_range[disabled]::-ms-thumb {
    background-color: #e3e5ed;
    cursor: default; }
  .rainbow-slider_range[disabled]::-ms-track {
    background-color: #e3e5ed;
    cursor: default; }

.rainbow-slider_value {
  padding: 0 0.5rem; }

.rainbow-slider_error {
  font-size: 0.875rem;
  margin-top: 0.5rem;
  color: #fe4849; }

.rainbow-slider_label--hide-label {
  position: absolute !important;
  margin: -1px !important;
  border: 0 !important;
  padding: 0 !important;
  width: 1px !important;
  height: 1px !important;
  overflow: hidden !important;
  clip: rect(0 0 0 0) !important;
  text-transform: none !important;
  white-space: nowrap !important; }
